export const PoiFacilities = [
  'airport_shuttle',
  'balcony',
  'bathtub',
  'coffee_machine',
  'cold',
  'cycling',
  'elevator',
  'family_room',
  'fitness_center',
  'food',
  'handicapped_friendly',
  'higher_toilet',
  'hiking',
  'kitchen',
  'lower_sink',
  'no_smoking',
  'parking',
  'pet',
  'pool',
  'private_bath',
  'reception_24',
  'room_service',
  'sauna',
  'spa_wellness',
  'terrace',
  'tv',
  'unit_on_ground_floor',
  'washing_machine',
  'water_heater',
  'wc_with_grab_1',
  'wc_with_grab_2',
  'wifi'
];
