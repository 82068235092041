import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {withLatestFrom} from 'rxjs/operators';

import {Stamp} from '../stamps/models';
import {StampsService} from '../stamps/services/stamps.service';
import {Trail} from '../trails/models';
import {TrailsService} from '../trails/services/trails.service';
import {Poi, PoiType} from '../poi/models';
import {PoiService} from '../poi/services/poi.service';
import {User} from '../users/models/user.model';
import {UsersService} from '../users/services/users.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  usersData: any = {};
  poisData: any = {};
  stampsData: any = {};
  photosData: any = {
    total: 0,
    lastWeek: 0,
    lastDay: 0,
    stamps: 0,
    hotels: 0,
    hostels: 0,
    attractions: 0,
  };
  trails$: Observable<Trail[]>;
  dayInMiliseconds = 1000 * 60 * 60 * 24;
  showStamps = false;
  showPhotos = false;
  showUsers = false;
  showPois = false;

  constructor(private stampsApi: StampsService,
              private trailsApi: TrailsService,
              private poisApi: PoiService,
              private usersApi: UsersService) {

  }

  ngOnInit() {
    this.trails$ = this.trailsApi.getAll();
    this.loadStamps();
    this.loadPois();
    this.loadUsers();
  }

  loadUsers() {
    this.usersApi.getAll().subscribe(users => {
      this.countUsers(users);
    });
  }

  countUsers(users: Array<User>) {
    if (!users) {
      return;
    }

    this.usersData = {
      total: users.length,
      lastDay: this.countNewUsers(users, this.dayInMiliseconds),
      lastWeek: this.countNewUsers(users, this.dayInMiliseconds * 7),
    };
    this.showUsers = true;
  }

  countNewUsers(users: Array<User>, period: number) {
    const now = new Date().getTime();
    return users.filter(stamp => stamp.createdAt > now - period).length;
  }

  loadPois() {
    this.poisApi.getAll()
      .pipe(withLatestFrom(this.trails$))
      .subscribe(([pois, trails]) => {
      this.countPoiData(pois, trails);
      this.countPoiPhotos(pois);
    });
  }

  countPoiData(pois: Array<Poi>, trails: Array<Trail>) {
    if (!pois || !pois.length) {
      return;
    }

    const hotels = pois.filter(poi => poi.type === PoiType.HOTEL);
    const hostels = pois.filter(poi => poi.type === PoiType.HOSTEL);
    const attractions = pois.filter(poi => poi.type === PoiType.ATTRACTION);

    this.poisData.total = {
      lodging: hotels.length + hostels.length,
      attractions: attractions.length,
    };

    if (trails) {
      trails.forEach(trail => {
        this.poisData[trail.id] = {
          lodging: hotels.filter(poi => poi.trail === trail.id).length + hostels.filter(poi => poi.trail === trail.id).length,
          attractions: attractions.filter(poi => poi.trail === trail.id).length,
        };
      });
    }

    this.showPois = true;
  }

  countPoiPhotos(pois: Array<Poi>) {
    if (!pois || !pois.length) {
      return;
    }

    const hotels = pois.filter(poi => poi.type === PoiType.HOTEL);
    const hostels = pois.filter(poi => poi.type === PoiType.HOSTEL);
    const attractions = pois.filter(poi => poi.type === PoiType.ATTRACTION);
    this.photosData.hotels = hotels.reduce((prev, hotel) => prev + hotel.photos.length, 0);
    this.photosData.hostels = hostels.reduce((prev, hostel) => prev + hostel.photos.length, 0);
    this.photosData.attractions = attractions.reduce((prev, attraction) => prev + attraction.photos.length, 0);
    this.showPhotos = true;
  }

  loadStamps() {
    this.stampsApi.getAll()
      .pipe(withLatestFrom(this.trails$))
      .subscribe(([stamps, trails]) => {
        this.countStamps(stamps, trails);
        this.countStampPhotos(stamps);
      });
  }

  countStamps(stamps: Array<Stamp>, trails: Array<Trail>) {
    if (!stamps) {
      return;
    }

    this.stampsData = {
      total: stamps.length || 0,
      lastDay: this.countNewStamps(stamps, this.dayInMiliseconds),
      lastWeek: this.countNewStamps(stamps, this.dayInMiliseconds * 7),
    };

    if (trails) {
      trails.forEach(trail => {
        this.stampsData[trail.id] = stamps.filter(stamp => stamp.trailId === trail.id).length;
      });
    }

    this.showStamps = true;
  }

  countNewStamps(stamps: Array<Stamp>, period: number) {
    const now = new Date().getTime();
    return stamps.filter(stamp => stamp.createdAt > now - period).length;
  }

  countStampPhotos(stamps: Array<Stamp>) {
    this.photosData.stamps = stamps.reduce((prev, stamp) => prev + stamp.photos.length, 0);
  }

  get photosTotal(): number {
    return this.photosData.stamps + this.photosData.hotels + this.photosData.hostels + this.photosData.attractions;
  }
}
