import {classToPlain, plainToClassFromExist} from 'class-transformer';

export class User {
  id: string;
  name: string;
  createdAt: number;

  constructor(entity: any = null) {
    plainToClassFromExist(this, entity);
  }

  deserialize() {
    const resp: any = {...classToPlain(this)};
    delete resp.id;
    return resp;
  }
}
