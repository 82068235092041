import {classToPlain, plainToClassFromExist, Type} from 'class-transformer';
import {Photo} from '../../shared/models';
import {GeoPoint} from '../../trails/models';

export class Stamp {
  id: string;
  title: string;
  description: string;
  userId: string;
  isActive: boolean;
  verified: boolean;

  @Type(() => GeoPoint)
  location: GeoPoint;
  imgRef: any;
  createdAt: number;
  trailId: string;
  stageId: string;
  photos: Photo[] = [];
  photoOwners: string[] = [];

  constructor(entity: any = null) {
    plainToClassFromExist(this, entity);
  }

  deserialize() {
    const photos = this.photos.map(x => x.deserialize());
    const photoOwners = this.photos.map(x => x.deserializeOwner());
    const location = this.location.deserialize();
    const resp: any = {...classToPlain(this), photos, photoOwners, location};
    delete resp.id;
    return resp;
  }
}
