import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {DeleteButtonDialogComponent} from './components/delete-button/delete-button-dialog.component';
import {DeleteButtonComponent} from './components/delete-button/delete-button.component';
import {FabButtonComponent} from './components/fab-button/fab-button.component';

import {LoaderComponent} from './components/loader/loader.component';
import {ErrorsComponent} from './components/errors/errors.component';
import {ClearInputDirective} from './directives/clear-input.directive';
import {AuthGuard, UnAuthGuard} from './guards/auth.guard';
import {MaterialModule} from './material/material.module';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { GalleryComponent } from './components/gallery/gallery.component';
import { PhotoComponent } from './components/photo/photo.component';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    DragDropModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    LoaderComponent,
    ErrorsComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    FabButtonComponent,
    TranslateModule,
    DeleteButtonComponent,
    ClearInputDirective,
    GalleryComponent,
    PhotoComponent
  ],
  declarations: [
    LoaderComponent,
    ErrorsComponent,
    FabButtonComponent,
    DeleteButtonDialogComponent,
    DeleteButtonComponent,
    ClearInputDirective,
    GalleryComponent,
    PhotoComponent
  ],
  providers: [
    AuthGuard,
    UnAuthGuard,
  ],
  entryComponents: [
    DeleteButtonDialogComponent,
  ],
})
export class SharedModule {
}
