import {HttpClient} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AngularFireModule, FirebaseOptionsToken} from '@angular/fire';
import {AngularFirestoreModule, FirestoreSettingsToken} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EffectsModule} from '@ngrx/effects';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {TranslateLoader, TranslateModule, TranslateModuleConfig} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {localStorageSync} from 'ngrx-store-localstorage';
import {environment} from '../environments/environment';
import {DashboardModule} from './dashboard/dashboard.module';
import {LayoutModule} from './layout/layout.module';
import {effects} from './store/app.effects';
import {reducers} from './store/app.reducers';

/* I18n */
export function createTranslateLoader(http: HttpClient) {
  const dateTime = new Date().getTime();
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cb=${dateTime}`);
}

const translateConfig: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: (createTranslateLoader),
    deps: [HttpClient],
  },
};

const I18N_MODULES = [
  TranslateModule.forRoot(translateConfig),
];

/* Store */

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({keys: ['layout', {auth: ['user']}], rehydrate: true})(reducer);
}

const STORE_MODULES = [
  StoreModule.forRoot(reducers, {metaReducers}),
  EffectsModule.forRoot(effects),
  StoreDevtoolsModule.instrument(),
];

/* Firebase */

const firebaseConfig = environment.firebaseConfig;

const FIREBASE_MODULES = [
  AngularFireModule.initializeApp(environment.firebaseConfig),
  AngularFirestoreModule,
  AngularFireAuthModule,
  AngularFireStorageModule,
];

const FIREBASE_PROVIDERS = [
  {provide: FirebaseOptionsToken, useValue: environment.firebaseConfig},
  {provide: FirestoreSettingsToken, useValue: {}},
];


/* App */
const MODULES = [
  AuthModule,
  DashboardModule,
  LayoutModule,
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ...MODULES,
    ...I18N_MODULES,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ...FIREBASE_MODULES,
    ...STORE_MODULES,
  ],
  providers: [
    ...FIREBASE_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
