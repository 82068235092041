import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Photo } from '../../models';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
})
export class PhotoComponent implements OnInit {

  @Input() photo: string;
  public photo$: Observable<Photo>;
  public isVisible = false;

  constructor(private afStorage: AngularFireStorage) {}

  ngOnInit() {
    this.photo$ = this.getPhoto(this.photo);
  }

  private getPhoto(id: string): Observable<Photo> {
    return this.afStorage.ref(id).getDownloadURL()
      .pipe(
        map((downloadUrl: string) => new Photo(id, downloadUrl)),
      );
  }

  public imageLoaded() {
    this.isVisible = true;
  }

}
