import {classToPlain, plainToClassFromExist} from 'class-transformer';
import {Photo} from '../../shared/models';

export class Stage {
  id: string;
  name: string;
  length: number;
  duration: number;
  sequence: number;
  description: string;
  isActive: boolean = false;
  photos: Photo[] = [];
  trail: string;

  constructor(entity: any = null) {
    entity.geoCoordinates = [];
    plainToClassFromExist(this, entity);
  }

  deserialize() {
    const photos = this.photos.map(x => x.deserialize());
    const resp: any = {...classToPlain(this), photos};
    delete resp.id;
    return resp;
  }
}
