import {plainToClassFromExist, Type, classToPlain} from 'class-transformer';
import {Photo} from '../../shared/models';
import {GeoPoint} from './geoPoint.model';
import {Stage} from '../../stages/models/stage.model';
import {Exclude} from 'class-transformer';

export class Trail {

  @Exclude({ toPlainOnly: true })
  id: string;

  name: string;
  description: string;
  categoryId: string;
  color: string;

  isActive: boolean;

  @Type(() => GeoPoint)
  coordinates: GeoPoint[] = [];

  photos: Photo[] = [];
  length: number;

  @Type(() => Stage)
  stages: Stage[];


  constructor(entity: any = null) {
    plainToClassFromExist(this, entity);
  }

  deserialize() {
    const photos = this.photos.map(x => x.deserialize());
    const coordinates = this.coordinates.map(g => g.deserialize());
    return {...classToPlain(this), coordinates, photos};
  }
}
