import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
    <div class="ac-loader" [ngClass]="{'accent': color == 'accent'}">
      <div class="spinner-container" *ngIf="!loaded">
        <mat-spinner [diameter]="diameter" [color]="color"></mat-spinner>
      </div>
      <div class="content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() loaded: boolean;
  @Input() public diameter: number = 24;
  @Input() public color: string = 'accent';
}
