import {classToPlain, plainToClassFromExist, Type} from 'class-transformer';
import {Photo} from '../../shared/models';
import {GeoPoint} from '../../trails/models';
import {PoiType} from './poiType.enum';

export class Poi {
  public id: string;
  public name: string;
  public photo: string;
  public address: string;
  public trail: string;
  public stage: string;

  @Type(() => GeoPoint)
  public coordinates: GeoPoint;
  public type: PoiType;
  public isActive: boolean;
  public verified: boolean;
  public description: string;
  public photos: Photo[] = [];
  public url: string;
  public email: string;
  public phone: string;
  public facilities: string[] = [];
  public priceCategory: number;

  @Type(() => Poi)
  public children: Poi[];

  public childrenIds: string[] = [];

  constructor(entity: any = null) {
    plainToClassFromExist(this, entity);
  }

  deserialize() {
    const photos = this.photos.map(x => x.deserialize());
    const coordinates = this.coordinates.deserialize();
    const resp: any = {...classToPlain(this), photos, coordinates};
    delete resp.id;
    return resp;
  }
}
