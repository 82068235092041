export class Photo {
  constructor(public id: string, public src: string, public owner: string = null) {
  }

  public deserialize(): string {
    return this.id;
  }

  public deserializeOwner(): string {
    return this.owner;
  }
}
