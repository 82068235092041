import {FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialogConfig} from '@angular/material';
import {Subscription} from 'rxjs';

export abstract class FormComponent {

  public fg: FormGroup;
  public isLoaded = false;
  public errors: string[] = [];

  public subscriptions: Subscription[] = [];

  public matDialogConfig(data: any = {}, mode: string = 'default', panelClass: string = ''): MatDialogConfig {

    const options = <MatDialogConfig>{
      width: '600px',
      height: '100%',
      maxHeight: '80%',
      maxWidth: '80%',
      hasBackdrop: true,
      autoFocus: true,
      position: {top: '10%'},
      panelClass
    };

    if (mode === 'fullscreen') {
      options.width = '100%';
      options.height = '100%';
      options.maxHeight = '100%';
      options.maxWidth = '100%';
      options.position = {top: '0'};
    }

    const result = <MatDialogConfig>{...options, ...data};
    return result;
  }

  protected load(): void {
    this.isLoaded = false;
    this.errors = [];
  }

  protected loaded(): void {
    this.isLoaded = true;
  }

  protected onError(response: HttpErrorResponse): void {
    this.isLoaded = true;
    this.errors = [response.message];
  }

  protected addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  protected unsubscribe(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());

  }



}
