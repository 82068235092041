export interface Roles {
    admin?: boolean;
    editor?: boolean;
}

export class User {
  public id: string;
  public email: string;
  public roles: Roles;

  constructor(user: any = null, roles: any = null) {
    this.id = '';
    this.email = '';
    this.roles = {
        admin: roles ? !!roles.admin : false,
        editor: roles ? !!roles.editor : false,
    };

    if (user) {
      this.id = user.uid;
      this.email = user.email;
    }
  }

  canAccessAdmin() {
      return this.roles.admin || this.roles.editor;
  }

}
