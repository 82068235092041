import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {SignInComponent} from './pages/sign-in/sign-in.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    SignInComponent,
  ],
  exports: [
    SignInComponent,
  ],
})
export class AuthModule {
}
