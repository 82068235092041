import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-fab-button',
  template: `
    <button mat-fab [matTooltip]="label | translate" [disabled]="disabled" matTooltipPosition="left" (click)="doClick()">
      <mat-icon>{{icon}}</mat-icon>
    </button>
  `,
  styleUrls: ['./fab-button.component.scss']
})
export class FabButtonComponent {

  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() icon = 'done';
  @Input() label = 'Save';
  @Input() disabled;

  doClick(): void {
    this.clicked.emit();
  }

}
