import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {from, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Photo} from '../../shared/models';
import {Trail} from '../models';
import {Reference, UploadTaskSnapshot} from '@angular/fire/storage/interfaces';

@Injectable({
  providedIn: 'root',
})
export class TrailsService {

  constructor(public db: AngularFirestore, private afStorage: AngularFireStorage) {
  }

  public create(trail: Trail): void {
    // trail.id = this.db.createId();
    this.db.collection('/trails').add(trail.deserialize());
  }

  public getAll(): Observable<Trail[]> {
    return this.db.collection('/trails', ref => ref.orderBy('isActive', 'desc')).snapshotChanges().pipe(
        map((resp: any) => resp.map((item => {
          return this.parseFromFireBase(item.payload.doc);
        }))));
  }


  public getById(id: string): Observable<Trail> {
    return this.db.collection('/trails').doc(id).snapshotChanges().pipe(
        map((item: any) => this.parseFromFireBase(item.payload)),
        // mergeMap((trail: Trail) =>
        //     this.getAllStagesByTrailId(trail.id).pipe(
        //         map((stages: Stage[]) => {
        //           trail.stages = stages;
        //           return trail;
        //         }),
        //     ),
        // ),
        mergeMap((trail: Trail) =>
            from(Promise.all(trail.photos.map((id) => this.photo(`${id}`).toPromise()))).pipe(
                map(((photos: Photo[]) => {
                  trail.photos = photos;
                  return trail;
                })),
            ),
        ),
    );

  }

  public updateActive(trail: Trail, isActive: boolean): void {
    this.db.collection('/trails').doc(trail.id).update({isActive});
  }

  public update(trail: Trail): void {
    this.db.collection('/trails').doc(trail.id).update(trail.deserialize());
  }

  public delete(trail: Trail): void {
    this.db.collection('/trails').doc(trail.id).delete();
  }

  private parseFromFireBase(item: any): Trail {
    return new Trail({id: item.id, ...item.data()});
  }

  private photo(id: string): Observable<Photo> {
    return this.afStorage.ref(id).getDownloadURL()
        .pipe(
            map((downloadUrl: string) => new Photo(id, downloadUrl)),
        );
  }

  public upload(file: any): Observable<Reference> {
    const id = Math.random().toString(36).substring(2);
    const ref = this.afStorage.ref(`trails/${id}`);
    const uploadPromise = ref.put(file);
    return from(uploadPromise).pipe(map((snapshot: UploadTaskSnapshot) => snapshot.ref));
  }

}

