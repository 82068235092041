import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {AppState} from '../../../store/app.state';
import {Store} from '@ngrx/store';
import {FormComponent} from '../../../shared/components/form/form.component';
import {AuthActions} from '../../store/auth.actions';
import {getErrors, isLoading} from '../../store/auth.selectors';

@Component({
  selector: 'app-auth-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends FormComponent {

  public isLoading$: Observable<boolean>;
  public errors$: Observable<string[]>;

  constructor(private store: Store<AppState>, protected fb: FormBuilder) {
    super();

    this.fg = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.isLoading$ = this.store.select(isLoading);
    this.errors$ = this.store.select(getErrors);

  }

  onSubmit() {
    if (this.fg.valid) {
      this.store.dispatch({type: AuthActions.SIGN_IN, payload: this.fg.value});
    }
  }

}
