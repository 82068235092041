import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Reference, UploadTaskSnapshot} from '@angular/fire/storage/interfaces';
import {from, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Photo} from '../../shared/models';
import {Stamp} from '../models';

@Injectable({
  providedIn: 'root',
})
export class StampsService {

  constructor(public db: AngularFirestore, private afStorage: AngularFireStorage) {
  }

  public getAll(trailId: string = null): Observable<Stamp[]> {
    let collection = this.db.collection('/stamps', ref => ref.orderBy('createdAt', 'desc'));

    if (trailId) {
      collection = this.db.collection('/stamps', ref => ref.where('trailId', '==', trailId).orderBy('createdAt', 'desc'));
    }

    return collection.snapshotChanges().pipe(
        map((resp: any) => resp.map((item) => this.parseFromFireBase(item.payload.doc))),
    );
  }

  public getById(id: string): Observable<Stamp> {
    return this.db.collection('/stamps').doc(id).snapshotChanges().pipe(
      map((item: any) => this.parseFromFireBase(item.payload)),
      mergeMap((stamp: Stamp) =>
        from(Promise.all(stamp.photos.map((id, index) => this.photo(`${id}`, this.getPhotoOwner(stamp, index)).toPromise()))).pipe(
          map(((photos: Photo[]) => {
            stamp.photos = photos;
            return stamp;
          })),
        ),
      ),
    );
  }

  public verify(stamp: Stamp, verified: boolean): void {
    this.db.collection('/stamps').doc(stamp.id).update({verified});
  }

  public update(stamp: Stamp, isActive: boolean): void {
    this.db.collection('/stamps').doc(stamp.id).update({isActive});
  }

  public updateStamp(stamp: Stamp): void {
    this.db.collection('/stamps').doc(stamp.id).update(stamp.deserialize());
  }

  public delete(stamp: Stamp): void {
    this.db.collection('/stamps').doc(stamp.id).delete();
  }

  private getPhotoOwner(stamp: Stamp, index: number): string {
    if (!stamp.photoOwners || !stamp.photoOwners.length) {
      return '';
    }

    return stamp.photoOwners[index];
  }

  private parseFromFireBase(item: any): Stamp {
    return new Stamp({id: item.id, ...item.data()});
  }

  private photo(id: string, userId: string): Observable<Photo> {
    return this.afStorage.ref(id).getDownloadURL()
      .pipe(
        map((downloadUrl: string) => new Photo(id, downloadUrl, userId)),
      );
  }

  public upload(file: any, userId: string): Observable<Reference> {
    const id = Math.random().toString(36).substring(2);
    const ref = this.afStorage.ref(`stamps/${userId}_${id}`);
    const uploadPromise = ref.put(file);
    return from(uploadPromise).pipe(map((snapshot: UploadTaskSnapshot) => snapshot.ref));
  }

}
