import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Title} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/app.state';
import {LayoutActions} from './layout.actions';
import {map} from 'rxjs/operators';

@Injectable()
export class LayoutEffects {

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private titleService: Title) {
  }

  @Effect({dispatch: false})
  titleChange$ = this.actions$.pipe(
      ofType(LayoutActions.SET_TILE),
      map((action: any) => {
        const title = action.payload.title;
        this.titleService.setTitle(title);
      }),
  );

}
