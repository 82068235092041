import * as fb from 'firebase/app';

export class GeoPoint {
  public _lat: number;
  public _long: number;

  constructor(lat: number, lng: number) {
    this._lat = lat;
    this._long = lng;
  }


  public deserialize(): fb.firestore.GeoPoint {
    return new fb.firestore.GeoPoint(this._lat, this._long);
  }
}
