import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {HeaderComponent} from './components/header/header.component';
import {MainComponent} from './components/main/main.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    MainComponent,
  ],
  exports: [
    MainComponent,
  ],
})
export class LayoutModule {}
