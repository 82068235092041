import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ImageSnippet, Photo} from '../../models';
import {FormComponent} from '../form/form.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent extends FormComponent implements OnInit {

  @Input() photos: Photo[] = [];
  @Input() limit: number = 0;

  @Output() onPhotoAdded: EventEmitter<ImageSnippet> = new EventEmitter<ImageSnippet>();
  @Output() onPhotoRemove: EventEmitter<Photo> = new EventEmitter<Photo>();
  @Output() onPhotosOrderChange: EventEmitter<Photo[]> = new EventEmitter<Photo[]>();

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public onFileChanged(fileList: FileList): void {
    Array.from(fileList).map((file: File) => this.readFile(file));
  }

  public drop(event: CdkDragDrop<Photo[]>) {
    moveItemInArray(this.photos, event.previousIndex, event.currentIndex);
    this.onPhotosOrderChange.emit(this.photos);
  }

  public remove(photo: Photo) {
    this.onPhotoRemove.emit(photo);
  }

  private readFile(file: File): void {
    const fileReader: FileReader = new FileReader();

    fileReader.addEventListener('load', (event: any) => {
      this.onPhotoAdded.emit(new ImageSnippet(event.target.result, file));
    });

    fileReader.readAsDataURL(file);
  }

  private openCropModal(img: ImageSnippet) {

  }

}
