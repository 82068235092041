import {NgModule} from '@angular/core';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatIconModule,
  MatIconRegistry,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatBadgeModule,
  MatPaginatorModule,
  MatChipsModule,
  MatProgressBarModule

} from '@angular/material';
import {MatDialogModule} from '@angular/material/dialog';
import {CdkTableModule} from '@angular/cdk/table';
import {MatSortModule} from '@angular/material/sort';
import {MatListModule} from '@angular/material/list';

@NgModule({
  exports: [
    MatBadgeModule,
    MatListModule,
    MatCardModule,
    MatAutocompleteModule,
    CdkTableModule,
    MatSidenavModule,
    MatTableModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDividerModule,
    MatMenuModule,
    MatToolbarModule,
    MatSortModule,
    MatPaginatorModule,
    MatChipsModule,
    MatProgressBarModule
  ],
  providers: [
    MatIconRegistry,
    MatNativeDateModule
  ]
})
export class MaterialModule {
}
