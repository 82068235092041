import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {Reference, UploadTaskSnapshot} from '@angular/fire/storage/interfaces';
import {from, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Photo} from '../../shared/models';
import {Poi} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PoiService {

  constructor(public db: AngularFirestore, private afStorage: AngularFireStorage) {
  }

  public create(pointOfInterest: Poi): void {
    this.db.collection('/pois').add(pointOfInterest.deserialize());
  }

  public getAll(trailId: string = null): Observable<Poi[]> {

    let collection = this.db.collection('/pois');

    if (trailId) {
      collection = this.db.collection('/pois', ref => ref.where('trail', '==', trailId));
    }

    return collection.snapshotChanges().pipe(
        map((resp: any) => resp.map(item => this.parseFromFireBase(item.payload.doc))),
    );
  }

  public getById(id: string): Observable<Poi> {
    return this.db.collection('/pois').doc(id).snapshotChanges().pipe(
        map((item: any) => this.parseFromFireBase(item.payload)),
        mergeMap((poi: Poi) =>
            from(Promise.all(poi.photos.map((id) => this.photo(`${id}`).toPromise()))).pipe(
                map(((photos: Photo[]) => {
                  poi.photos = photos;
                  return poi;
                })),
            ),
        ),
        // mergeMap((poi: Poi) =>
        //     from(Promise.all(poi.childrenIds.map((id) => this.getByIdSimple(id).toPromise()))).pipe(
        //         map(((items: Poi[]) => {
        //           poi.children = items;
        //           return poi;
        //         })),
        //     ),
        // ),
    );
  }

  public getByIdSimple(id: string): Observable<Poi> {
    return this.db.collection('/pois').doc(id).snapshotChanges().pipe(
        map((item: any) => this.parseFromFireBase(item.payload)),
    );
  }

  public verify(pointOfInterest: Poi, verified: boolean): void {
    this.db.collection('/pois').doc(pointOfInterest.id).update({verified});
  }

  public updateActive(pointOfInterest: Poi, isActive: boolean): void {
    this.db.collection('/pois').doc(pointOfInterest.id).update({isActive});
  }

  public update(pointOfInterest: Poi): void {
    this.db.collection('/pois').doc(pointOfInterest.id).update(pointOfInterest.deserialize());
  }

  public delete(pointOfInterest: Poi): void {
    this.db.collection('/pois').doc(pointOfInterest.id).delete();
  }

  private parseFromFireBase(item: any): Poi {
    return new Poi({id: item.id, ...item.data()});
  }

  private photo(id: string): Observable<Photo> {
    return this.afStorage.ref(id).getDownloadURL()
        .pipe(
            map((downloadUrl: string) => new Photo(id, downloadUrl)),
        );
  }

  public upload(file: any): Observable<Reference> {
    const id = Math.random().toString(36).substring(2);
    const ref = this.afStorage.ref(`pois/${id}`);
    const uploadPromise = ref.put(file);
    return from(uploadPromise).pipe(map((snapshot: UploadTaskSnapshot) => snapshot.ref));
  }

}

