import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'mo-delete-button-dialog',
  styleUrls: ['./delete-button-dialog.component.scss'],
  template: `
    <mat-dialog-content>
        <p class="message">{{ data.message }}</p>
    </mat-dialog-content>
    <div class="actions" mat-dialog-actions align="center">
      <button mat-stroked-button mat-dialog-close>{{ "common.cancel" | translate }}</button>
      <button mat-raised-button color="warn" (click)="delete()">{{ "common.delete" | translate }}</button>
    </div>
  `
})
export class DeleteButtonDialogComponent {

  constructor(public dialogRef: MatDialogRef<DeleteButtonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  delete(): void {
    this.dialogRef.close(true);
  }

}
