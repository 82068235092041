import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthGuard} from './shared/guards/auth.guard';

const routes: Routes = [
  {path: 'dashboard', data: {title: 'Camino Admin'}, component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'auth', loadChildren: './auth/auth-routing.module#AuthRoutingModule'},
  {path: 'categories', loadChildren: './categories/trail-categories-routing.module#TrailCategoriesRoutingModule', canActivate: [AuthGuard]},
  {path: 'trails', loadChildren: './trails/trails-routing.module#TrailsRoutingModule', canActivate: [AuthGuard]},
  {path: 'stages', loadChildren: './stages/stages-routing.module#StagesRoutingModule', canActivate: [AuthGuard]},
  {path: 'pois', loadChildren: './poi/poi-routing.module#PoiRoutingModule', canActivate: [AuthGuard]},
  {path: 'stamps', loadChildren: './stamps/stamps-routing.module#StampsRoutingModule', canActivate: [AuthGuard]},
  {path: 'reports', loadChildren: './reports/reports-routing.module#ReportsRoutingModule', canActivate: [AuthGuard]},
  {path: '', redirectTo: '/auth/sign-in', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
