import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {AuthActions} from '../../../auth/store/auth.actions';
import {getEmail, isLogged} from '../../../auth/store/auth.selectors';
import {AppState} from '../../../store/app.state';
import {LayoutActions} from '../../store/layout.actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  public isLogged$: Observable<boolean>;
  public email$: Observable<string>;
  public title$: Observable<string>;

  constructor(private store: Store<AppState>) {
    this.isLogged$ = this.store.select(isLogged);
    this.email$ = this.store.select(getEmail);
    this.title$ = this.store.select('layout', 'title');
  }

  public signOut() {
    this.store.dispatch({
      type: AuthActions.SIGN_OUT,
      payload: {redirect: true}
    });
  }

  public sideNavToggle() {
    this.store.dispatch({
      type: LayoutActions.SIDE_NAV_TOGGLE
    });
  }

}
