import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {isLogged} from '../../../auth/store/auth.selectors';
import {AppState} from '../../../store/app.state';
import {LayoutActions} from '../../store/layout.actions';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {

  public isLogged$: Observable<boolean>;
  public isSideNavOpened$: Observable<boolean>;

  constructor(private store: Store<AppState>, private activatedRoute: ActivatedRoute, private router: Router) {
    this.isLogged$ = this.store.select(isLogged);
    this.isSideNavOpened$ = this.store.select('layout', 'sideNavOpened');

    this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
    ).subscribe(event => {
      const title = `${event.title}`;
      this.store.dispatch({type: LayoutActions.SET_TILE, payload: {title}});
    });


  }

}
