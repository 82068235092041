import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormComponent} from '../form/form.component';
import {MatDialog} from '@angular/material';
import {DeleteButtonDialogComponent} from './delete-button-dialog.component';

@Component({
  selector: 'app-delete-button',
  template: `

      <button mat-raised-button color="accent" *ngIf="type === 'button'" type="button" (click)="openDialog()">
          {{ 'common.deleteSelected' | translate }}
      </button>

      <button *ngIf="type === 'icon-button'" type="button" (click)="openDialog()" mat-icon-button [matTooltip]="'common.delete' | translate"
              matTooltipPosition="above">
          <mat-icon>delete</mat-icon>
      </button>


  `,
})
export class DeleteButtonComponent extends FormComponent {

  @Input() message: string;
  @Input() type: string = 'icon-button';
  @Output() onDelete: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog) {
    super();
  }

  openDialog() {

    const message = this.message;

    const options = this.matDialogConfig({
      data: { message },
      height: 'auto',
      top: '50%'
    }, 'popup', 'deleteAll-button-panel');

    //@here
    this.dialog.open(DeleteButtonDialogComponent, options).afterClosed().subscribe(result => {
      if (result) {
        this.onDelete.emit(true);
      }
    });
  }

}
