import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  constructor(public db: AngularFirestore) {
  }

  public getAll(): Observable<User[]> {
    const collection = this.db.collection('/username');

    return collection.snapshotChanges().pipe(
      map((resp: any) => resp.map((item) => this.parseFromFireBase(item.payload.doc))),
    );
  }

  private parseFromFireBase(item: any): User {
    return new User({
      id: item.id, ...item.data()
    });
  }
}
