import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from '../models';
import { AuthActions } from './auth.actions';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {

  constructor(private router: Router,
    private actions$: Actions,
    private afAuth: AngularFireAuth,
    private db: AngularFirestore) {
  }

  @Effect({ dispatch: false })
  signOut$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_OUT),
    tap(() => this.router.navigate(['/auth/sign-in'])),
  );

  @Effect({ dispatch: false })
  signInSuccess$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN_SUCCESS),
    tap(() => this.router.navigate(['/dashboard'])),
  );

  @Effect()
  signIn$ = this.actions$.pipe(
    ofType(AuthActions.SIGN_IN),
    map((action: any) => ({ ...action.payload })),
    switchMap((payload) => {
      const signInObservable$ = from(this.signIn(payload));
      let response;

      return from(signInObservable$).pipe(
        tap(r => response = r),
        flatMap(() => this.db.collection('users').doc(response.user.uid).get()),
        map((doc) => {
          if (!response || !response.user) {
            throw response.error;
          }

          if (!doc.exists) {
            throw new Error('User does not exist');
          }

          const payloadUser: User = new User(response.user, doc.data().roles);

          if (!payloadUser.canAccessAdmin()) {
            throw new Error('You are not an admin or editor');
          }

          return { type: AuthActions.SIGN_IN_SUCCESS, payload: payloadUser };
        }),
        catchError((error) => of({ type: AuthActions.SIGN_IN_FAILURE, payload: error.message })),
      );
    }),
  );

  private signIn({ email, password }) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).catch(error => ({ error }));
  }


}
